<script setup lang="ts">
import { nextTick, ref, watch, markRaw } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import mockSidebar from '@/components/tutorialmocks/Sidebar.vue'
import mockLayersmenu from '@/components/tutorialmocks/Layersmenu.vue'
import mockProjectsSlider from '@/components/tutorialmocks/AllprojectsSlide.vue'
import mockSectionsSlider from '@/components/tutorialmocks/SectionsSlide.vue'
import mockPointsSlider from '@/components/tutorialmocks/PointsSlide.vue'

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const publicPath = window.location.origin

const mockelements = ref([
  {
    element: 'sidebar',
    module: markRaw(mockSidebar),
    show: false,
    pages: ['all'],
    elementToHide: 'originalsidebar'
  },
  {
    element: 'layersmenu',
    module: markRaw(mockLayersmenu),
    show: false,
    pages: ['map.projects', 'map.sections', 'map.locations'],
    elementToHide: 'originallayersmenu'
  },
  {
    element: 'projectsSlideover',
    module: markRaw(mockProjectsSlider),
    show: false,
    pages: ['map.projects'],
    elementToHide: 'originalslideover'
  },
  {
    element: 'sectionsSlideover',
    module: markRaw(mockSectionsSlider),
    show: false,
    pages: ['map.sections'],
    elementToHide: 'originalslideover'
  },
  {
    element: "pointsSlideover",
    module: markRaw(mockPointsSlider),
    show: false,
    pages: ['map.locations'],
    elementToHide: 'originalslideover'
  }
])

const tutorialelements = [
  {
    element: "adresssearch",
    textbox: "bottom",
    title: "Zoek een adres",
    message: "Zoek een adres om op de kaart te navigeren",
  },
  {
    custom: true,
    element: "projectsSlideover",
    textbox: "left",
    title: "Alle projecten",
    message: "Hier vind je een lijst van alle projecten die zich op de kaart bevinden",
  },
  {
    custom: true,
    element: "sectionsSlideover",
    textbox: "left",
    title: "Alle deelprojecten",
    message: "Hier vind je een lijst van alle deelprojecten die zich op de kaart bevinden",
  },
  {
    custom: true,
    element: "pointsSlideover",
    textbox: "left",
    title: "Alle meetpunten",
    message: "Hier vind je een lijst van alle meetpunten die zich op de kaart bevinden",
  },
  {
    custom: true,
    element: "layersmenu",
    textbox: "top",
    title: "Kaartlagen",
    message: "Hier kan je de kaartlagen aanpassen",
  },
  {
    element: "streetviewbutton",
    textbox: "top",
    title: "Streetview",
    message: "Hier kan je de streetview activeren",
  },
  {
    custom: true,
    element: "sidebar",
    textbox: "right",
    title: "Navigatie",
    message: "Hier kan je navigeren naar verschillende pagina's",
  },
  {
    element: "projectslist",
    textbox: "center",
    title: "Projectenlijst",
    message: "Hier vind je een lijst van alle projecten waar jij toegang tot hebt",
  },
  {
    element: "tablehead",
    textbox: "bottom",
    title: "Sortering",
    message: "Hier kan je zoeken en sorteren binnen de tabel",
  },
  {
    element: "projecttableitem",
    textbox: "bottom",
    title: "Project details",
    message: "Hier vind je de details van een project",
  },
  {
    element: "projecttablecode",
    textbox: "bottom",
    title: "Project code",
    message: "Hier vind je de code van het project",
  },
  {
    element: "projecttablename",
    textbox: "bottom",
    title: "Project naam",
    message: "Hier vind je de naam van het project",
  },
  {
    element: "projecttablestatus",
    textbox: "bottom",
    title: "Project status",
    message: "Hier vind je de status van het project",
  },
  {
    element: "projecttablesections",
    textbox: "bottom",
    title: "Deelprojecten",
    message: "Hier vind je de deelprojecten van het project",
  },
  {
    element: "projecttablemap",
    textbox: "smoothprojactions",
    title: "Kaart",
    message: "Hier kan je navigeren naar het project op de kaart"
  },
  {
    element: 'projecttablechart',
    textbox: 'smoothprojactions',
    title: 'Grafiek',
    message: 'Hier vind je de grafiek van het project'
  },
  {
    element: 'projecttablepivot',
    textbox: 'smoothprojactions',
    title: 'Pivot tabel',
    message: 'Hier vind je de pivot tabel van het project'
  },
  {
    element: 'projecttableedit',
    textbox: 'smoothprojactions',
    title: 'Bewerken',
    message: 'Hier kan je het project bewerken'
  },
  // ALTIJD ALS LAATSTE HOUDEN
  {
    element: "emptydiv",
    textbox: "bottom",
    title: "Tutorial afgerond",
    message: "Je hebt de tutorial van deze pagina afgerond",
    finished: true,
  }
]
const currentelement = ref(-1);
const disabledelement = ref({element: null, originalstate: ''})

const nextelement = async() => {
  mockelements.value.forEach((element) => {
    element.show = false
  })
  if (disabledelement.value.element) {
    disabledelement.value.element.style.opacity = disabledelement.value.originalstate
  }
  for (let i = currentelement.value + 1; i < Object.keys(tutorialelements).length; i++) {
    if (tutorialelements[i].custom) {
      const moduleIndex = mockelements.value.findIndex((element) => element.element === tutorialelements[i].element)
      if (moduleIndex !== -1 && (mockelements.value[moduleIndex].pages.includes('all') || mockelements.value[moduleIndex].pages.includes(route.name))) {
        if (mockelements.value[moduleIndex].elementToHide) {
          const elementToHide = document.getElementById(mockelements.value[moduleIndex].elementToHide);
          disabledelement.value = {element: elementToHide, originalstate: elementToHide?.style.opacity}
          if (elementToHide) {
            elementToHide.style.opacity = '0'
          }
        }
        mockelements.value[moduleIndex].show = true
        await nextTick()
        currentelement.value = i
        break
      }
    } else {
      const element = tutorialelements[i].element;
      const testelement = document.getElementById(element);
      if (testelement) {
        currentelement.value = i
        break
      }
    }
  }
}

const stoptutorial = () => {
  mockelements.value.forEach((element) => {
    element.show = false
  })
  if (disabledelement.value.element) {
    disabledelement.value.element.style.opacity = disabledelement.value.originalstate
  }
  currentelement.value = -1
  store.dispatch('interactabletutorial/stop')
}

const getClass = (element: any) => {
  const testelement = document.getElementById(element);
  const boundingClient = testelement?.getBoundingClientRect();
  const test = buildClass(boundingClient)
  return test
}

const getSize = (element: any) => {
  const testelement = document.getElementById(element);
  const boundingClient = testelement?.getBoundingClientRect();
  const test = buildSize(boundingClient)
  return test
}

const getWidth = (element: any) => {
  const testelement = document.getElementById(element);
  const boundingClient = testelement?.getBoundingClientRect();
  const test = buildWidth(boundingClient)
  return test
}

const getTextPos = (element: any) => {
  const testelement = document.getElementById(element.element);
  const boundingClient = testelement?.getBoundingClientRect();
  const textboxelement = document.getElementById('textbox');
  const textboxboundingClient = textboxelement?.getBoundingClientRect();
  const shadowelement = document.getElementById('shadowbox');
  const shadowboundingClient = shadowelement?.getBoundingClientRect();
  if (boundingClient && boundingClient.height !== undefined && boundingClient.width !== undefined) {
    if (element.textbox === 'bottom') {
      return `top: ${boundingClient.top + boundingClient.height + 15}px;`
    } else if (element.textbox === 'left') {
      return `left: ${boundingClient.left - boundingClient.width - 15}px;`
    } else if (element.textbox === 'right') {
      return `left: ${boundingClient.right + 15}px;`
    } else if (element.textbox === 'top') {
      return `top: ${boundingClient.top - textboxboundingClient.height - 15}px;`
    } else if (element.textbox === 'center') {
      return `top: ${boundingClient.top + boundingClient.height / 2 - textboxboundingClient.height / 2}px; left: ${boundingClient.width / 2 - boundingClient.left / 2}px;`
    } else if (element.textbox === 'smoothprojactions') {
      return `top: ${boundingClient.top + boundingClient.height + 15}px; left: ${boundingClient.left - textboxboundingClient.width + shadowboundingClient.width}px;`
    }
  } else {
    return '';
  }
}

const buildSize = (boundingClient: any) => {
  if (boundingClient && boundingClient.height !== undefined && boundingClient.width !== undefined) {
    return `height: ${boundingClient.height}px; width: ${boundingClient.width}px;`;
  } else {
    return '';
  }
}

const buildClass = (boundingClient: any) => {
  if (boundingClient && boundingClient.top !== undefined && boundingClient.left !== undefined) {
    return `position: absolute; top: ${boundingClient.top}px; left: ${boundingClient.left}px;`;
  } else {
    return '';
  }
}

const buildWidth = (boundingClient: any) => {
  if (boundingClient && boundingClient.width !== undefined) {
    if (boundingClient.width < 320) {
    return `width: 320px;`;
    }
    if (boundingClient.width > 640) {
      return `width: 640px;`;
    } else {
      return `width: ${boundingClient.width}px;`;
    }
  } else {
    return '';
  }
}

watch(() => store.getters['interactabletutorial/started'], (value) => {
  if (value === true) {
    nextelement()
  }
})
</script>

<template>
  <div class="absolute mx-6 my-2" id="emptydiv"></div>
  <Teleport to="#app">
   <div v-if="store.getters['interactabletutorial/started'] && currentelement >= 0" class="fixed inset-0 z-[80] w-full pointer-events-none select-none" id="interactabletutorial">
      <div :style="getClass(tutorialelements[currentelement].element)" @click="stoptutorial">
        <div id="shadowbox" class="bg-transparent rounded-lg pointer-events-none ring ring-black ring-opacity-10 " style="box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.8); border-radius: 0.5rem;" :style="getSize(tutorialelements[currentelement].element)"></div>
      </div>
      <div style="transition: top 0.3s ease, left 0.3s ease, width 0.3s ease, height 0.3s ease;" :style="getClass(tutorialelements[currentelement].element) + ' ' + getTextPos(tutorialelements[currentelement])">
        <div id="textbox" class="bg-white rounded-lg shadow-google ring ring-black ring-opacity-10 p-2.5 pointer-events-auto" :style="getWidth(tutorialelements[currentelement].element)">
          <div class="flex justify-between">
            <h3 class="text-md font-semibold text-gray-900">{{ tutorialelements[currentelement].title }}</h3>
          </div>
          <p class="text-sm text-gray-600">{{ tutorialelements[currentelement].message }}</p>
          <p v-if="tutorialelements[currentelement].finished" class="text-sm text-gray-600">{{ t('need more in-depth information') }}?
            <RouterLink :to="{ name: 'manuals'} " class="text-blue-600 hover:underline">{{ t('Manual', 2) }}</RouterLink>
          </p>
          <div class="flex mt-4 justify-between ">
            <button id="stopbutton" class="btn-danger-inverted first-letter:capitalize" @click="stoptutorial">
              {{ t('stop') }}
            </button>
            <button id="nextbutton" v-if="!tutorialelements[currentelement].finished" class="btn-primary first-letter:capitalize" @click="nextelement">
              {{ t('next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
  <div v-for="element in mockelements" :key="element.element">
    <component class="z-[99]" :show="element.show" :is="element.module" />
  </div>
</template>
<style scoped>
.smooth-transition {
  transition: all 0.3s ease-in-out;
}
</style>
