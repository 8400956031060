import { AuthState } from '@/types/store/Auth'
import { ActionContext } from "vuex"

type Context = ActionContext<AuthState, {}>

// initial state
const state = () => ({
  JWTToken: ''
})

// getters
const getters = {
  JWTToken: (state: AuthState): string => state.JWTToken
}

// actions
const actions = {
  setJWTToken({ commit, getters, dispatch }: any, JWTToken: string) {
    commit('setJWTToken', JWTToken)
    const decodedJWTToken = decodeJWTToken(JWTToken)
    commit('user/setId', decodedJWTToken.oid, { root: true })
    commit('user/setName', decodedJWTToken.name, { root: true })
    commit('user/setUsername', decodedJWTToken.preferred_username, { root: true })
  },
}

// mutations
const mutations = {
  setJWTToken(state: AuthState, JWTToken: string) {
    state.JWTToken = JWTToken
  }
}

function decodeJWTToken(JWTToken: string): any {
  const base64Url = JWTToken.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
