import { RouteLocation, RouteRecordRaw } from 'vue-router'
import MeasureValuesChildRoutes from '@/router/measure-values'
import pointParamAssessmentRoutes from '@/router/point-param-assessments'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'edit',
    name: 'measure-point.edit',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/Edit.vue'),
  },
  {
    path: 'imports',
    name: 'measure-point.imports',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/Imports.vue'),
    redirect: (to: RouteLocation) => ({ name: 'measure-point.imports.list', params: to.params }),
    children: [
      {
        path: 'list',
        name: 'measure-point.imports.list',
        component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/imports/List.vue'),
      },
      {
        path: 'new',
        name: 'measure-point.import.create',
        component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/imports/Create.vue'),
      },
    ]
  },
  {
    path: 'measure-values',
    name: 'measure-values',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/MeasureValues.vue'),
    // redirect: (to: RouteLocation) => ({ name: 'measure-points.map', params: to.params }),
    children: MeasureValuesChildRoutes
  },
  {
    path: 'information',
    name: 'measure-point.information',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/Information.vue'),
    redirect: (to: RouteLocation) => ({ name: 'measure-point-information.information', params: { ...to.params } }),
    children: [
      {
        path: 'information',
        name: 'measure-point-information.information',
        component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/information/Information.vue'),
      },
      {
        path: 'new',
        name: 'measure-point-information.create',
        component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/information/Create.vue'),
      },
    ]
  },
  {
    path: 'logbook',
    name: 'measure-point.logbook',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/Logbook.vue'),
  },
  {
    path: 'param-assessments',
    name: 'point.param-assessments',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/ParamAssessments.vue'),
    redirect: () => ({ name: 'point.param-assessments.list' }),
    children: pointParamAssessmentRoutes
  },
]

export default routes
